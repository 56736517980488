import React from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { TPA_EXPERIMENTS } from '@wix/pricing-plans-common/experiments';
import { VIEWER_HOOKS } from '@wix/pricing-plans-common/hooks';
import { useCurrencyFormatter, useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { useDynamicElement } from '../../../../../hooks';
import { useLocale } from '../../../../../hooks/useLocale';
import { getFormattedAdditionalFees } from '../../../../../utils/get-formatted-additional-fees';
import { st, classes } from './SetupFee.st.css';

interface Props {
  plan: PublicPlan;
  planId: string;
  highlighted: boolean;
}

export const SetupFee: React.FC<Props> = ({ plan, planId, highlighted }) => {
  const { isMobile } = useEnvironment();
  const shouldUseDynamicSetupFeeHeight = !isMobile;
  const { heightByPlanId, registerRef } = useDynamicElement('setup-fee');
  const { locale } = useLocale();
  const { experiments } = useExperiments();

  const fees = getFormattedAdditionalFees({
    plan,
    locale,
    createCurrencyFormatter: useCurrencyFormatter,
    enabled: experiments.enabled(TPA_EXPERIMENTS.SETUP_FEE),
  })
    .map(({ amount, name }) => `+${amount} ${name}`)
    .join('\n');

  const ref = React.useMemo(() => registerRef(planId), [planId, registerRef]);

  return (
    <div
      className={st(classes.root, { highlighted, mobile: isMobile })}
      data-hook={VIEWER_HOOKS.PLAN_SETUP_FEE}
      style={shouldUseDynamicSetupFeeHeight ? { height: heightByPlanId.get(planId) } : {}}
    >
      <div className={classes.content} ref={shouldUseDynamicSetupFeeHeight ? ref : undefined}>
        {fees}
      </div>
    </div>
  );
};

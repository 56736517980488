import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { FormattedAdditionalFee } from '@wix/pricing-plans-tpa-api';
import { CreateCurrencyFormatter } from '@wix/yoshi-flow-editor';
import { getFormattedPriceData } from '../hooks';

export function getFormattedAdditionalFees(params: {
  plan: PublicPlan;
  locale?: string;
  createCurrencyFormatter: CreateCurrencyFormatter;
  enabled: boolean;
}): FormattedAdditionalFee[] {
  const { enabled, plan, locale, createCurrencyFormatter } = params;
  if (!enabled || !plan.pricing?.feeConfigs?.length) {
    return [];
  }

  return plan.pricing.feeConfigs.map((fee) => {
    const formatted = getFormattedPriceData({
      createCurrencyFormatter,
      locale,
      value: fee.fee?.amount,
      currency: plan.pricing?.price?.currency,
      hideTrailingFractionZeroes: true,
    });

    return { name: fee.fee?.name!, amount: `${formatted.fullPrice}` };
  });
}
